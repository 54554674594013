<template>
  <v-container>
    <v-row>
      <v-col>
        <span class="text-h4">Editar Feriado</span>
      </v-col>
    </v-row>
    <v-divider> </v-divider>
    <br />
    <div>
      <div v-if="this.feriado">
        <FeriadoForm :feriado="this.feriado"></FeriadoForm>
      </div>
    </div>
  </v-container>
</template>

<script>
import FeriadoForm from "../components/FeriadoForm.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "FeriadoEdit",
  components: {
    FeriadoForm,
  },
  data() {
    return {
      feriado: null,
    };
  },
  props: ["id"],
  created() {
    PresentismoService.getFeriado(this.id)
      .then((response) => {
        this.feriado = response.data;
      })
      .catch((error) => console.log(error));
  },
};
</script>
